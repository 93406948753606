import React from 'react'


//import ContactForm from './contactform'

//import contactStyle from './contact.module.css'
import Banner from '../../banner'
import SectionContact from './sectionContact'

const ContactPage2 = () => (
    <>
    <Banner />
    <SectionContact />

    </>
)

export default ContactPage2