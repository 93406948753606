import React from 'react'
import Layout from '../components/layout'
import ContactPage2 from '../components/PageComponents/Contact2/ContactPage2'

const Contact2 = () => (
    <Layout>
        <ContactPage2 />

    </Layout>
)

export default Contact2